
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code7 } from "./data";

let id = 1000;

export default defineComponent({
  name: "custom-node-content",
  components: {
    CodeHighlighter
  },
  data() {
    const data = [
      {
        id: 1,
        label: "Level one 1",
        children: [
          {
            id: 4,
            label: "Level two 1-1",
            children: [
              {
                id: 9,
                label: "Level three 1-1-1"
              },
              {
                id: 10,
                label: "Level three 1-1-2"
              }
            ]
          }
        ]
      },
      {
        id: 2,
        label: "Level one 2",
        children: [
          {
            id: 5,
            label: "Level two 2-1"
          },
          {
            id: 6,
            label: "Level two 2-2"
          }
        ]
      },
      {
        id: 3,
        label: "Level one 3",
        children: [
          {
            id: 7,
            label: "Level two 3-1"
          },
          {
            id: 8,
            label: "Level two 3-2"
          }
        ]
      }
    ];
    return {
      data: JSON.parse(JSON.stringify(data))
    };
  },

  methods: {
    append(data) {
      const newChild = { id: id++, label: "testtest", children: [] };
      if (!data.children) {
        data.children = [];
      }
      data.children.push(newChild);
      this.data = [...this.data];
    },

    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
      this.data = [...this.data];
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    renderContent(h, { node, data, store }) {
      return h(
        "span",
        {
          class: "custom-tree-node"
        },
        h("span", null, node.label),
        h(
          "span",
          null,
          h(
            "a",
            {
              onClick: () => this.append(data)
            },
            "Append "
          ),
          h(
            "a",
            {
              onClick: () => this.remove(node, data)
            },
            "Delete"
          )
        )
      );
    }
  },
  setup() {
    return {
      code7
    };
  }
});
