
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/data/tree/BasicUsage.vue";
import EUISelectable from "@/views/resources/documentation/element-ui/data/tree/Selectable.vue";
import EUICustomLeafNodeInLazyMode from "@/views/resources/documentation/element-ui/data/tree/CustomLeafNodeInLazyMode.vue";
import EUIDisabledCheckbox from "@/views/resources/documentation/element-ui/data/tree/DisabledCheckbox.vue";
import EUIDefaultExpandedAndDefaultChecked from "@/views/resources/documentation/element-ui/data/tree/DefaultExpandedAndDefaultChecked.vue";
import EUICheckingTreeNodes from "@/views/resources/documentation/element-ui/data/tree/CheckingTreeNodes.vue";
import EUICustomNodeContent from "@/views/resources/documentation/element-ui/data/tree/CustomNodeContent.vue";
import EUITreeNodeFiltering from "@/views/resources/documentation/element-ui/data/tree/TreeNodeFiltering.vue";
import EUIAccordion from "@/views/resources/documentation/element-ui/data/tree/Accordion.vue";
import EUIDraggable from "@/views/resources/documentation/element-ui/data/tree/Draggable.vue";

export default defineComponent({
  name: "tree",
  components: {
    EUIBasicUsage,
    EUISelectable,
    EUICustomLeafNodeInLazyMode,
    EUIDisabledCheckbox,
    EUIDefaultExpandedAndDefaultChecked,
    EUICheckingTreeNodes,
    EUICustomNodeContent,
    EUITreeNodeFiltering,
    EUIAccordion,
    EUIDraggable
  },
  setup() {
    setCurrentPageTitle("Tree");
  }
});
